import { withCookies, useCookies } from "react-cookie";
import { useEffect, useState, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import useReports from "services/ReportService";
import Button from "components/Button";
import FilterPanel from "pages/Home/FilterPanel";
import Icon from "components/Icon";
import Modal from "components/Modal";
import Table from "components/Table";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { classNames } from 'primereact/utils';
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { InputText } from 'primereact/inputtext';
import { IconField } from 'primereact/iconfield';
import { InputIcon } from 'primereact/inputicon';
import EditControls from "components/Table/EditControls";
import { Skeleton } from 'primereact/skeleton';

function Home() {
  // const [initialLoadComplete, setInitialLoadComplete] = useState(false);
  const [filterPanelOpen, setFilterPanelOpen] = useState(false);
  const toggleFilterPanel = () => setFilterPanelOpen(filterPanelOpen => !filterPanelOpen);
  const [filterOptions, setFilterOptions] = useState({ client: [] });
  // const [filter, setFilter] = useState({});
  const { reports, getReports, setReports, newReport } = useReports();

  const fetchData = async (filter) => {
    try {
      setLoading(true);
      const reportsData = await getReports(filter);

      setReports(reportsData.reports);
      setFilterOptions(reportsData.filters);
      setLoading(false);
    } catch (error) {
      console.error(error);
    }
  };

  // useEffect(() => {
  //   loadFiltersFromLocalStorage();
  //   setInitialLoadComplete(true);
  // }, []);

  // useEffect(() => {
  //   if (initialLoadComplete) {
  //     fetchData();
  //   }
  // }, [initialLoadComplete, filter]);

  useEffect(()=> {
    const client = localStorage.getItem("client");
    const dateStart = localStorage.getItem('dateStart');
    const dateEnd = localStorage.getItem('dateEnd');
    fetchData({
      ...((client && client !== "[]") && { "client": JSON.parse(client) }),
      ...(dateStart && { "dateStart": new Date(dateStart).toISOString().split('T')[0] }),
      ...(dateEnd && { "dateEnd": new Date(dateEnd).toISOString().split('T')[0] })
    });
  }, []);
  
  const [cookies, setCookie, removeCookie] = useCookies(["cookie-name"]);
  let [welcomeModalOpen, setWelcomeModalOpen] = useState(!cookies["user"]);
  
  

  // function closeWelcome() {
  //   setWelcomeModalOpen(false);
  //   let id = (Math.random() + 1).toString(36).substring(7);
  //   setCookie("user", id, { path: "/" });
  // }


  const [modalOpen, setModalOpen] = useState(false);
  const closeModal = () => setModalOpen(false);
  const openModal = () => setModalOpen(true);

  const tableActions = [
    {
      name: "View report",
      parameter: "id",
      route: "/report/",
      section: "/report-overview"
    },
  ];

  const [tableFilters, setTableFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
  });
  const [searchFilter, setSearchFilter] = useState('');

  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    let _filters = { ...tableFilters };

    _filters['global'].value = value;

    setTableFilters(_filters);
    setSearchFilter(value);
  };

  const dateTemplate = (rowData) => {
    // const day = date.toLocaleString("default", { day: "2-digit" });
    // const month = date.toLocaleString("default", { month: "short" });
    // const year = date.toLocaleString("default", { year: "numeric" });
    // return `${year}-${month}-${day}`;
    return (
      loading ?
      <Skeleton /> :
      new Date(rowData.date).toLocaleDateString('en-US', { year: 'numeric', month: 'short', day: 'numeric' })
    );
  };

  const history = useNavigate();

  const handleAction = (action, item) => {
    if (action.route) {
      // history.push(`${action.route}${item}`);
      history(`${action.route}${item}${action.section}`);
    }
  };

  const viewReportTemplate = (rowData) => {
    return (
      loading ?
      <Skeleton /> :
      <Button onClick={() => handleAction(tableActions[0], rowData.id)} className="table-view">View Report</Button>
    );
  };

  const editTemplate = (rowData) => {
    return (
      loading ?
      <Skeleton /> :
      <EditControls item={rowData.id} fetchData={fetchData} />
    );
  };

  const [loading, setLoading] = useState(true)
;
  return (
    <>
      <FilterPanel
        filterOptions={filterOptions}
        fetchData={fetchData}
        filterPanelOpen={filterPanelOpen}
        toggleFilterPanel={toggleFilterPanel}
      />
      {/* {welcomeModalOpen && (
        <Modal
          step={modalStep}
          submitForm={submitForm}
          type={"welcome"}
          hidden={false}
          closeModal={closeWelcome}
          changeModalStep={changeModalStep}
          isLoading={isLoading}
        ></Modal>
      )} */}

        <Modal
          type={"input"}
          isOpen={modalOpen}
          closeModal={closeModal}
          clients={filterOptions.client}
          newReport={newReport}
        ></Modal>

      <div className="hero">
        <h1 className="hero__heading">All Reports</h1>
        <div className="hero__search relative">
          <IconField iconPosition="left">
            <InputIcon>
              <Icon icon="search_line"/>
            </InputIcon>
            <InputText value={searchFilter} onChange={onGlobalFilterChange} placeholder="Search" />
          </IconField>
        </div>
        <div className="hero__actions">
          <Button
            iconBefore="add_line"
            className="hero__actions-create-report"
            onClick={openModal}
          >
            Create New Report
          </Button>
          <Button
            iconBefore="filter_line"
            variant="secondary"
            className="hero__actions-filter-reports"
            onClick={toggleFilterPanel}
          >
            Filter Reports
          </Button>
        </div>
      </div>
      <DataTable value={loading ? Array.from({ length: 5 }, (v, i) => i) : reports} filters={tableFilters}
        globalFilterFields={["title", "client"]} sortField="date" sortOrder={-1}
        emptyMessage="No reports found."
        sortIcon={(options) => options.sorted ? options.sortOrder === 1 ? <Icon icon="up_small_line"/> : <Icon icon="down_small_line"/> : <Icon icon="selector_vertical_line"/> }
        className="architect-table"
        scrollable
        scrollHeight="700px"
      >
        <Column field="title" header="Report title" sortable body={loading ? <Skeleton /> : ""}/>
        <Column field="client" header="Client" sortable body={loading ? <Skeleton /> : ""}/>
        <Column field="date" header="Date created" sortable body={dateTemplate}/>
        <Column field="view" body={viewReportTemplate} className="architect-table__row-actions" style={{ width: '138px' }}/>
        <Column field="edit" body={editTemplate} className="table__row-controls" style={{ width: '110px' }}/>
      </DataTable>
    </>
  );
}
export default withCookies(Home);
