import Button from "components/Button";

export default function DownloadButton({
  file,
  style,
  disabled,
  text
}) {
  const handleDownload = () => {
    const anchor = document.createElement('a');
    anchor.href = file;
    anchor.download = 'architect_template.xlsx'; // Set the desired file name
    anchor.click();
  };
  return (
    <Button
      iconBefore={"download_2_line"}
      onClick={handleDownload}
      disabled={disabled}
      style={style}
    >
      {text}
    </Button>
  );
}
