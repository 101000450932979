import {
  ResponsiveContainer,
  CartesianGrid,
  ComposedChart,
  Tooltip,
  Legend,
  XAxis,
  YAxis,
  Line,
  Bar,
} from "recharts";
import ReportItem from "../../ReportItem";
import TableBlock from "../TableBlock";
import CustomTooltip from "./CustomTooltip";

function Wrapper({ children, container, ...other }) {
  if (container === false) return <div {...other}>{children}</div>;
  return <ReportItem {...other}>{children}</ReportItem>;
}

const X_AXIS_FONT_SIZE = 12;

export default function PvMixChartBlock({
  id,
  title,
  data,
  cols,
  colors = [],
  loading,
  barField,
  sortField,
  sortOrder,
  footer,
  fields,
  container = true,
  className,
  xAxisField = "category",
}) {
  // Calculate the height of the tick labels by finding the longest label

  const findLongestText = (list, xAxisField) => {
    let longestText = '';
    list.forEach(item => {
      if (item[xAxisField].length > longestText.length) {
        longestText = item[xAxisField];
      }
    });
    return longestText;
  };

  const getTextWidth = (text, fontSize, ) => {
    // Create a temporary element
    const tempElement = document.createElement('span');
    
    // Set the text content and styles
    tempElement.style.fontSize = fontSize;
    tempElement.style.visibility = 'hidden'; // Ensure the element is not visible
    tempElement.style.whiteSpace = 'pre'; // Preserve whitespace
    tempElement.textContent = text;
    
    // Append the element to the body to get the computed width
    document.body.appendChild(tempElement);
    const width = tempElement.offsetWidth;
    
    // Remove the element from the DOM
    document.body.removeChild(tempElement);
    
    return width;
  }

  const longestText = findLongestText(data, xAxisField);

  // Calculate the width of the longest text
  const textWidth = getTextWidth(longestText, X_AXIS_FONT_SIZE);


  // Helpers
  const sortData = (data) => {
    return data.sort((a, b) => b.value - a.value);
  };

  return (
    <Wrapper
      id={id}
      container={container}
      className={`${className} report-block__pv-chart`}
    >
      <div className="report-item__heading">
        <h2 className="report-item__heading-title">{title}</h2>
      </div>
      <div
        className="report-item__data"
        style={{
          gridTemplateColumns: "repeat(1, 1fr)",
        }}
      >
        {data && (
          <div
            className="report-item__data-line-mix"
            style={{ height: "635px" }}
          >
            <ResponsiveContainer width="100%" height="100%">
              <ComposedChart
                data={sortData(data)}
                layout="horizontal"
                barSize={5}
                margin={{ top: 10, right: 0, bottom: 30, left: 0 }}
              >
                <CartesianGrid stroke="#E5E5E9" vertical={false} />
                <XAxis
                  type="category"
                  dataKey={fields[0]}
                  tickLine={false}
                  stroke="#000"
                  tickMargin={4}
                  height={textWidth}
                  tick={{
                    angle: -90,
                    textAnchor: "end",
                    fontSize: X_AXIS_FONT_SIZE,
                    fontWeight: 500,

                    // Offset the tick labels to the left
                    dx: -(X_AXIS_FONT_SIZE / 3),
                  }}
                  /* 
                    Ensures ticks are displayed even if they overlap.
                    We are not concerned with overlapping ticks
                    since we are rotating the labels
                  */
                  minTickGap={-Infinity}
                />

                <YAxis
                  yAxisId="value"
                  type="number"
                  dataKey={sortField}
                  tickFormatter={formatNumberWithAbbreveation}
                  tickLine={false}
                  tick={{ fontSize: 10, fill: "#AEAEBC" }}
                  tickCount={6}
                  axisLine={false}
                  width={38}
                />
                <YAxis
                  orientation="right"
                  yAxisId="percentage"
                  type="number"
                  tickFormatter={(value) => `${value}%`}
                  // domain={[0, 100]}
                  tickLine={false}
                  tick={{ fontSize: 10, fill: "#AEAEBC" }}
                  tickCount={6}
                  axisLine={false}
                  width={38}
                />
                <Legend iconType="circle" iconSize={10} />
                <Tooltip content={<CustomTooltip barField={barField} />} />

                {fields.map((field, index) => {
                  if (field === xAxisField) return null;

                  if (field === barField)
                    return (
                      <Bar
                        key={field}
                        name={cols[index]}
                        yAxisId="value"
                        dataKey={sortField}
                        fill={colors[index] ?? "#000"}
                        radius={[10, 10, 0, 0]}
                        activeBar={{ opacity: 0.5 }}
                        label={{
                          fill: "#000",
                          fontSize: 10,
                          position: "top",
                          formatter: (value) => value && value.toLocaleString(),
                        }}
                      />
                    );

                  return (
                    <Line
                      key={field}
                      name={cols[index]}
                      type="monotone"
                      strokeWidth={2}
                      dataKey={field}
                      stroke={colors[index] ?? "#000"}
                      fill={colors[index] ?? "#000"}
                      yAxisId={"percentage"}
                    />
                  );
                })}
              </ComposedChart>
            </ResponsiveContainer>
          </div>
        )}
        <TableBlock
          data={data}
          cols={cols}
          colors={colors}
          fields={fields}
          sortField={sortField}
          sortOrder={sortOrder}
          footer={footer}
          loading={loading}
          container={false}
        />
      </div>
    </Wrapper>
  );
}

function formatNumberWithAbbreveation(num) {
  if (num >= 1_000_000_000) return `${num / 1_000_000_000}B`;
  if (num >= 1_000_000) return `${num / 1_000_000}M`;
  if (num >= 1000) return `${num / 1000}K`;
  return num;
}
