import { getTotal } from "./utils";

export const revenueTrafficOpportunity = ({ report, brands }) => [
  {
    multiBlock: {
      id: "traffic-opportunities",
      title: "Traffic Opportunities",
      items: [
        {
          id: "rto_traffic_category",
          chartBlock: {
            container: false,
            title: "Top 5 By Category",
            cols: [
              "Category",
              "Est. Current Clicks",
              "Yearly Traffic Gain In #1",
            ],
            colors: [null, "#D490E5", "var(--primary)"],
            fields: ["category", "clicks", "traffic_gain"],
            sortField: "traffic_gain",
            sortOrder: -1,
            footer: [
              "Grand Total",
              getTotal({
                report,
                sheet: "rto_traffic_category",
                field: "clicks",
              }),
              getTotal({
                report,
                sheet: "rto_traffic_category",
                field: "traffic_gain",
              }),
            ],
          },
        },
        {
          id: "rto_traffic_sub",
          chartBlock: {
            container: false,
            title: "Top 5 By Sub-Category",
            cols: [
              "Category",
              "Est. Current Clicks",
              "Yearly Traffic Gain In #1",
            ],
            colors: [null, "#D490E5", "var(--primary)"],
            fields: ["category", "clicks", "traffic_gain"],
            sortField: "traffic_gain",
            sortOrder: -1,
            footer: [
              "Grand Total",
              getTotal({ report, sheet: "rto_traffic_sub", field: "clicks" }),
              getTotal({
                report,
                sheet: "rto_traffic_sub",
                field: "traffic_gain",
              }),
            ],
          },
        },
        {
          id: "rto_traffic_type",
          chartBlock: {
            container: false,
            title: "Top 5 By Type",
            cols: [
              "Category",
              "Est. Current Clicks",
              "Yearly Traffic Gain In #1",
            ],
            colors: [null, "#D490E5", "var(--primary)"],

            fields: ["category", "clicks", "traffic_gain"],
            sortField: "traffic_gain",
            sortOrder: -1,
            footer: [
              "Grand Total",
              getTotal({ report, sheet: "rto_traffic_type", field: "clicks" }),
              getTotal({
                report,
                sheet: "rto_traffic_type",
                field: "traffic_gain",
              }),
            ],
          },
        },
      ],
    },
  },

  {
    multiBlock: {
      id: "revenue-opportunities",
      title: "Revenue Opportunities",
      items: [
        {
          id: "rto_revenue_category",
          chartBlock: {
            container: false,
            title: "Top 5 By Category",
            cols: [
              "Category",
              "Est. Current Revenue",
              "Yearly Revenue Gain In #1",
            ],
            colors: [null, "#D490E5", "var(--primary)"],

            fields: ["category", "revenue", "revenue_gain"],
            sortField: "revenue_gain",
            sortOrder: -1,
            swap: true,
            footer: [
              "Grand Total",
              getTotal({
                report,
                sheet: "rto_revenue_category",
                field: "revenue",
              }),
              getTotal({
                report,
                sheet: "rto_revenue_category",
                field: "revenue_gain",
              }),
            ],
          },
        },
        {
          id: "rto_revenue_sub",
          chartBlock: {
            container: false,
            title: "Top 5 By Sub-Category",
            cols: [
              "Category",
              "Est. Current Revenue",
              "Yearly Revenue Gain In #1",
            ],
            colors: [null, "#D490E5", "var(--primary)"],

            fields: ["category", "revenue", "revenue_gain"],
            sortField: "revenue_gain",
            sortOrder: -1,
            swap: true,
            footer: [
              "Grand Total",
              getTotal({ report, sheet: "rto_revenue_sub", field: "revenue" }),
              getTotal({
                report,
                sheet: "rto_revenue_sub",
                field: "revenue_gain",
              }),
            ],
          },
        },
        {
          id: "rto_revenue_type",
          chartBlock: {
            container: false,
            title: "Top 5 By Type",
            cols: [
              "Category",
              "Est. Current Revenue",
              "Yearly Revenue Gain In #1",
            ],
            colors: [null, "#D490E5", "var(--primary)"],

            fields: ["category", "revenue", "revenue_gain"],
            sortField: "revenue_gain",
            sortOrder: -1,
            swap: true,
            footer: [
              "Grand Total",
              getTotal({ report, sheet: "rto_revenue_type", field: "revenue" }),
              getTotal({
                report,
                sheet: "rto_revenue_type",
                field: "revenue_gain",
              }),
            ],
          },
        },
      ],
    },
  },

  // {
  //   multiBlock: {
  //     id: "har-traffic-opportunities",
  //     title: "HAR Traffic Opportunities",
  //     items: [
  //       {
  //         id: "rto_har_traffic_category",
  //         chartBlock: {
  //           container: false,
  //           title: "Top 5 By Category",
  //           cols: [
  //             "Category",
  //             "Est. Current Clicks",
  //             "Yearly Traffic Gain In #1",
  //           ],
  //           colors: [null, "#D490E5", "var(--primary)"],

  //           fields: ["category", "clicks", "traffic_gain"],
  //           sortField: "traffic_gain",
  //           sortOrder: -1,
  //           footer: [
  //             "Grand Total",
  //             getTotal({
  //               report,
  //               sheet: "rto_har_traffic_category",
  //               field: "clicks",
  //             }),
  //             getTotal({
  //               report,
  //               sheet: "rto_har_traffic_category",
  //               field: "traffic_gain",
  //             }),
  //           ],
  //         },
  //       },
  //       {
  //         id: "rto_har_traffic_type",
  //         chartBlock: {
  //           container: false,
  //           title: "Top 5 By Type",
  //           cols: [
  //             "Category",
  //             "Est. Current Clicks",
  //             "Yearly Traffic Gain In #1",
  //           ],
  //           colors: [null, "#D490E5", "var(--primary)"],

  //           fields: ["category", "clicks", "traffic_gain"],
  //           sortField: "traffic_gain",
  //           sortOrder: -1,
  //           footer: [
  //             "Grand Total",
  //             getTotal({
  //               report,
  //               sheet: "rto_har_traffic_type",
  //               field: "clicks",
  //             }),
  //             getTotal({
  //               report,
  //               sheet: "rto_har_traffic_type",
  //               field: "traffic_gain",
  //             }),
  //           ],
  //         },
  //       },
  //     ],
  //   },
  // },

  // {
  //   multiBlock: {
  //     id: "har-revenue-opportunities",
  //     title: "HAR Revenue Opportunities",
  //     items: [
  //       {
  //         id: "rto_har_revenue_category",
  //         chartBlock: {
  //           container: false,
  //           title: "Top 5 By Category",
  //           cols: [
  //             "Category",
  //             "Est. Current Revenue",
  //             "Yearly Revenue Gain In #1",
  //           ],
  //           fields: ["category", "revenue", "revenue_gain"],
  //           sortField: "revenue_gain",
  //           sortOrder: -1,
  //           footer: [
  //             "Grand Total",
  //             getTotal({
  //               report,
  //               sheet: "rto_har_revenue_category",
  //               field: "revenue",
  //             }),
  //             getTotal({
  //               report,
  //               sheet: "rto_har_revenue_category",
  //               field: "revenue_gain",
  //             }),
  //           ],
  //         },
  //       },
  //       {
  //         id: "rto_har_revenue_sub",
  //         chartBlock: {
  //           container: false,
  //           title: "Top 5 By Sub-Category",
  //           cols: [
  //             "Category",
  //             "Est. Current Revenue",
  //             "Yearly Revenue Gain In #1",
  //           ],
  //           colors: [null, "#D490E5", "var(--primary)"],
  //           fields: ["category", "revenue", "revenue_gain"],
  //           sortField: "revenue_gain",
  //           sortOrder: -1,
  //           footer: [
  //             "Grand Total",
  //             getTotal({
  //               report,
  //               sheet: "rto_har_revenue_sub",
  //               field: "revenue",
  //             }),
  //             getTotal({
  //               report,
  //               sheet: "rto_har_revenue_sub",
  //               field: "revenue_gain",
  //             }),
  //           ],
  //         },
  //       },
  //       {
  //         id: "rto_har_revenue_type",
  //         chartBlock: {
  //           container: false,
  //           title: "Top 5 By Type",
  //           cols: [
  //             "Category",
  //             "Est. Current Revenue",
  //             "Yearly Revenue Gain In #1",
  //           ],
  //           colors: [null, "#D490E5", "var(--primary)"],
  //           fields: ["category", "revenue", "revenue_gain"],
  //           sortField: "revenue_gain",
  //           sortOrder: -1,
  //           footer: [
  //             "Grand Total",
  //             getTotal({
  //               report,
  //               sheet: "rto_har_revenue_type",
  //               field: "revenue",
  //             }),
  //             getTotal({
  //               report,
  //               sheet: "rto_har_revenue_type",
  //               field: "revenue_gain",
  //             }),
  //           ],
  //         },
  //       },
  //     ],
  //   },
  // },
];
