import { getTotal } from "./utils";

export const featureFormat = ({ report }) => [
  {
    id: "ff_type",
    chartBlock: {
      title: "SERP Feature Result Type",
      cols: ["Feature", "Count", "% share"],
      fields: ["feature", "count", "share"],
      sortField: "count",
      sortOrder: -1,
      footer: [
        "Grand Total",
        getTotal({ report, sheet: "ff_type", field: "count" }),
      ],
    },
  },
  {
    id: "ff_owned",
    chartBlock: {
      title: "Owned SERP Features",
      cols: ["Feature", "Count", "Client Owned"],
      fields: ["feature", "count", "client_owned"],
      sortField: "client_owned",
      sortOrder: -1,
      swap: 1,
      footer: [
        "Grand Total",
        getTotal({ report, sheet: "ff_owned", field: "count" }),
        getTotal({ report, sheet: "ff_owned", field: "client_owned" }),
      ],
    },
  },
  {
    id: "ff_competitor",
    chartBlock: {
      title: "Top SERP Features Owned By Competitor",
      cols: ["website", "% share"],
      fields: ["website", "share"],
      sortField: "share",
      sortOrder: -1,
      // container: true,
    },
  },
];
