import { createContext, useContext, useState } from "react";

const ReportContext = createContext({});

export default function ReportContextProvider({ children }) {
  const [report, setReport] = useState({});
  const [section, setSection] = useState('performance-dashboard');

  return (
    <ReportContext.Provider value={{ report, setReport, section, setSection }}>
      {children}
    </ReportContext.Provider>
  );
}

export function useReportContext() {
  const report = useContext(ReportContext);

  if (!report) {
    throw new Error(
      "useReportContext must be used within a ReportContextProvider."
    );
  }

  return report;
}
