import { StrictMode } from "react";
import "primereact/resources/themes/lara-light-purple/theme.css";
import "./assets/styles/styles.scss";
import ReactDOM from "react-dom/client";
import App from "App";
import { CookiesProvider } from "react-cookie";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <StrictMode>
    <CookiesProvider defaultSetOptions>
      <App />
    </CookiesProvider>
  </StrictMode>
);
