import { NavLink } from "react-router-dom";

export default function AccordionContent({ content, list, type }) {
  const renderListItem = (item) => {
    const { className: providedClassName, name, to, key, ...other } = item;
    const className = providedClassName ?? "";

    switch (type) {
      case "navigation":
        return (
          <NavLink
            key={name}
            to={to}
            className={`accordion__list-item ${className}`}
            {...other}
          >
            {name}
          </NavLink>
        );
      case "jump":
        return (
          <a
            key={key}
            href={"#" + key}
            className={`accordion__list-item ${className}`}
            {...other}
          >
            {name}
          </a>
        );
      case "links":
        return (
          <a
            key={item.name}
            href={item.to}
            className={`accordion__list-item ${className}"}`}
            {...other}
          >
            {name}
          </a>
        );
      default:
        return (
          <li
            key={item.name}
            className={`accordion__list-item ${className}`}
            {...other}
          >
            {name}
          </li>
        );
    }
  };

  return type !== "basic" ? (
    <ul className="accordion__list">
      {list.map((item) => renderListItem(item))}
    </ul>
  ) : (
    <div className="accordion__content">{content}</div>
  );
}
