import React from "react";
import { useSidebarContext } from "context/SidebarContext";

export default function ReportItem({ id, className, unstyled, ...other }) {
  const { highlightedSectionBlock } = useSidebarContext();
  const isHighlighted = highlightedSectionBlock === id;

  return (
    <div
      {...other}
      id={id}
      className={`${unstyled ? "" : "report-item"} ${className ?? ""} ${isHighlighted ? "current" : ""}`}
    />
  );
}
