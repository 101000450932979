import { getTotal } from "./utils";

const chartColors = [null, null, "var(--primary)", "#00CACF"];

export const uxDashboard = ({ report, brands }) => [
  {
    id: "ux_category_tbe",
    pvMixChartBlock: {
      title: "Category | Est. Traffic Vs Bounce Rate and Exit Rate",
      cols: [
        "Category",
        "Est. Current Clicks",
        "Average Exit Rate",
        "Average Bounce Rate",
      ],
      colors: chartColors,
      fields: ["category", "clicks", "exit_rate", "bounce_rate"],
      sortField: "clicks",
      barField: "clicks",
      xAxisField: "category",
      sortOrder: -1,
      footer: [
        "Grand Total",
        getTotal({ report, sheet: "ux_category_tbe", field: "clicks" }),
      ],
      container: true,
    },
  },
  {
    id: "ux_category_tcvr",
    pvMixChartBlock: {
      title: "Category | Est. Traffic Vs CVR",
      cols: ["Category", "Est. Current Clicks", "CVR"],
      colors: chartColors,
      fields: ["category", "clicks", "cvr"],
      sortField: "clicks",
      barField: "clicks",
      xAxisField: "category",
      sortOrder: -1,
      footer: [
        "Grand Total",
        getTotal({ report, sheet: "ux_category_tcvr", field: "clicks" }),
      ],
      container: true,
    },
  },
  {
    id: "ux_template_tbe",
    pvMixChartBlock: {
      title: "Page Template | Est. Traffic Vs Bounce Rate and Exit Rate",
      cols: [
        "Template",
        "Est. Current Clicks",
        "URL Bounce Rate",
        "URL Exit Rate",
      ],
      fields: ["template", "clicks", "exit_rate", "bounce_rate"],
      colors: chartColors,
      barField: "clicks",
      sortField: "clicks",
      xAxisField: "template",
      sortOrder: -1,
      footer: [
        "Grand Total",
        getTotal({ report, sheet: "ux_template_tbe", field: "clicks" }),
      ],
      container: true,
    },
  },
  {
    id: "ux_template_tcvr",
    pvMixChartBlock: {
      title: "Page Template | Est. Traffic Vs CVR",
      cols: ["Template", "Est. Current Clicks", "CVR"],
      fields: ["template", "clicks", "cvr"],
      sortField: "clicks",
      barField: "clicks",
      xAxisField: "template",
      colors: chartColors,
      sortOrder: -1,
      footer: [
        "Grand Total",
        getTotal({ report, sheet: "ux_template_tcvr", field: "clicks" }),
      ],
      container: true,
    },
  },
];
