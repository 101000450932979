import { getTotal } from "./utils";

export const competitorPerformance = ({ report, brands }) => [
  {
    id: "cp_traffic",
    chartBlock: {
      title: "Share of Estimated Traffic",
      cols: ["Websites", "Est. Current Clicks", "% share"],
      fields: ["websites", "clicks", "share"],
      sortField: "clicks",
      sortOrder: -1,
      footer: [
        "Grand Total",
        getTotal({ report, sheet: "cp_traffic", field: "clicks" }),
      ],
    },
  },
  {
    id: "cp_keyword_gaps",
    chartBlock: {
      title: "Keyword Gaps",
      cols: ["Label", "Gaps"],
      fields: ["label", "gaps"],
      sortField: "gaps",
      sortOrder: 1,
      swap: true,
      footer: [
        "Grand Total",
        getTotal({ report, sheet: "cp_keyword_gaps", field: "gaps" }),
      ],
    },
  },
  {
    id: "cp_comp_rankings",
    tableBlock: {
      title: "Competitor Rankings",
      cols: ["Category", brands[0], brands[1], brands[2], brands[3], brands[4]],
      fields: [
        "category",
        brands[0],
        brands[1],
        brands[2],
        brands[3],
        brands[4],
      ],
      sortField: brands[0],
      sortOrder: -1,
      footer: [
        "Grand Total",
        getTotal({ report, sheet: "cp_comp_rankings", field: brands[0] }),
        getTotal({ report, sheet: "cp_comp_rankings", field: brands[1] }),
        getTotal({ report, sheet: "cp_comp_rankings", field: brands[2] }),
        getTotal({ report, sheet: "cp_comp_rankings", field: brands[3] }),
        getTotal({ report, sheet: "cp_comp_rankings", field: brands[4] }),
      ],
      container: true,
    },
  },
  {
    id: "cp_comp_traffic",
    tableBlock: {
      title: "Competitor Estimated Traffic",
      cols: ["Category", brands[0], brands[1], brands[2], brands[3], brands[4]],
      fields: [
        "category",
        brands[0],
        brands[1],
        brands[2],
        brands[3],
        brands[4],
      ],
      sortField: brands[0],
      sortOrder: -1,
      footer: [
        "Grand Total",
        getTotal({ report, sheet: "cp_comp_traffic", field: brands[0] }),
        getTotal({ report, sheet: "cp_comp_traffic", field: brands[1] }),
        getTotal({ report, sheet: "cp_comp_traffic", field: brands[2] }),
        getTotal({ report, sheet: "cp_comp_traffic", field: brands[3] }),
        getTotal({ report, sheet: "cp_comp_traffic", field: brands[4] }),
      ],
      container: true,
    },
  },
  {
    id: "cp_comp_revenue",
    tableBlock: {
      title: "Competitor Estimated Revenue",
      cols: ["Category", brands[0], brands[1], brands[2], brands[3], brands[4]],
      fields: [
        "category",
        brands[0],
        brands[1],
        brands[2],
        brands[3],
        brands[4],
      ],
      sortField: brands[0],
      sortOrder: -1,
      footer: [
        "Grand Total",
        getTotal({ report, sheet: "cp_comp_revenue", field: brands[0] }),
        getTotal({ report, sheet: "cp_comp_revenue", field: brands[1] }),
        getTotal({ report, sheet: "cp_comp_revenue", field: brands[2] }),
        getTotal({ report, sheet: "cp_comp_revenue", field: brands[3] }),
        getTotal({ report, sheet: "cp_comp_revenue", field: brands[4] }),
      ],
      container: true,
    },
  },
];
