import Icon from "components/Icon";

export default function AccordionTitle({ icon, isOpen, children, onClick }) {
  return (
    <div onClick={onClick} className="accordion__title">
      <Icon icon={icon} className="accordion__icon" />
      <h5 className="accordion__title-text">{children}</h5>
      {!isOpen && <Icon icon="down_line" className="accordion__chevron" />}
      {isOpen && <Icon icon="up_line" className="accordion__chevron" />}
    </div>
  );
}
