import { useNavigate, useParams } from "react-router-dom";
import { useReportContext } from "context/ReportContext";
import Accordion from "components/Accordion";
import Button from "components/Button";
import Icon from "components/Icon";
import logo from "assets/images/architect-logo.svg";
import { useOktaAuth } from "@okta/okta-react";
import { useSidebarContext } from "context/SidebarContext";
import { Link } from "react-router-dom";

export default function HorizontalLayout({ children }) {
  const navigate = useNavigate();
  const { authState, oktaAuth } = useOktaAuth();
  const { id } = useParams();
  const { report } = useReportContext();

  const { section, setSection } = useReportContext();
  const { setHighlightedSectionBlock, navLinks } = useSidebarContext();


  const logout = async () => {
    await oktaAuth.signOut();
  };

  const openAccordion = (url) => {
    const section = navLinks.find((link) => link.url === url);
    if (!section) return;

    const firstSubLink = section.subList[0];
    if (firstSubLink) {
      setHighlightedSectionBlock(firstSubLink.key);
    }

    setSection(url);
    navigate(`/report/${id}/${url}`);
  };

  return (
    <main className="horizontal">
      <header className="report-header">
        <Link to="/" className="logo-link">
          <img className="logo-link__image" src={logo} alt="Architect Logo" />
        </Link>
        <div className="report-header__details">
          <div className="report-header__details-info">
            <div className="report-header__details-info-item">
              <div>
                <div className="report-header__details-info-item-label">
                  Report Name
                </div>
                <div className="report-header__details-info-item-value text-bold">
                  {report.title}
                </div>
              </div>
            </div>
            <div className="report-header__details-info-item">
              <div>
                <div className="report-header__details-info-item-label">
                  Client
                </div>
                <div className="report-header__details-info-item-value">
                  {report.client}
                </div>
              </div>
            </div>
            <div className="report-header__details-info-item">
              <div>
                <div className="report-header__details-info-item-label">
                  Industry
                </div>
                <div className="report-header__details-info-item-value">
                  {report.industry}
                </div>
              </div>
            </div>
            {report.currency && (
              <div className="report-header__details-info-item">
                <div>
                  <div className="report-header__details-info-item-label">
                    Currency
                  </div>
                  <div className="report-header__details-info-item-value">
                    {report.currency}
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="report-header__details-actions">
            <Icon
              className="report-header__details-actions-item"
              icon="edit_2_line"
            />
            <Icon
              className="report-header__details-actions-item"
              icon="share_forward_line"
            />
            <Icon
              className="report-header__details-actions-item"
              icon="download_2_line"
            />
            <div className="report-header__details-actions-more">
              <Icon
                className="report-header__details-actions-item"
                icon="more_1_line"
              />
            </div>
          </div>
        </div>
        <li className="profile">
          <div className="profile__picture">
            <Icon icon="user_3_line" />
          </div>
          <div className="profile__name">
            {authState.accessToken.claims.sub}
          </div>
        </li>
        <li>
          <Button
            className="logout"
            onClick={logout}
          >
            Logout
          </Button>
        </li>
      </header>
      <div id="main">
        <section className="side-nav">
          <div className="side-nav__navigation-container">
          <Button
            className="side-nav__home-link"
            iconBefore="home_2_line"
            to="/"
            type="router-link"
          >
            All Reports
          </Button>
            <div className="side-nav__navigation">
              {navLinks.map((linkData) => (
                <NavLink
                  key={linkData.url}
                  linkData={linkData}
                  onOpen={openAccordion}
                  currentSection={section}
                />
              ))}
            </div>
          </div>
          {/* <div className="side-nav__help" style={{ pointerEvents: "none" }}>
            <a className="side-nav__help-link" href="/support">
              <Icon icon="question_line" />
              Support
            </a>
            <a className="side-nav__help-link" href="/contact">
              <Icon icon="mail_line" />
              Contact
            </a>
          </div> */}
        </section>
        <section className="report" key={section}>
          {children}
        </section>
      </div>
    </main>
  );
}

function NavLink({ linkData, currentSection, onOpen }) {
  const { highlightedSectionBlock, setHighlightedSectionBlock } = useSidebarContext();
  
  if (linkData.hideIfSubListEmpty && !linkData.subList?.length) return null;

  // Highlight the current link
  const sublist = linkData.subList.map((subLink) => {
    const onClick = () => {
      setHighlightedSectionBlock(subLink.key);
    };

    if (subLink.key !== highlightedSectionBlock) {
      return { ...subLink, onClick };
    }

    return {
      ...subLink,
      className: `${subLink.className ?? ""} current`,
      onClick,
    };
  });

  return (
    <Accordion
      className={`side-nav__navigation-segment ${linkData.className}`}
      icon={linkData.icon}
      list={sublist}
      title={linkData.title}
      type={linkData.type}
      isOpen={currentSection === linkData.url}
      url={linkData.url}
      openAccordion={onOpen}
    />
  );
}
