import { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import Button from "components/Button";
import Icon from "components/Icon";
import CommentsPanel from "./CommentsPanel";
import { useReportContext } from "context/ReportContext";
import useReports from "services/ReportService";
import { sections as generateSections } from "./sections/sections";
import Block from "./blocks/Block";
import { NavigationScrollListener } from "context/SidebarContext";
import Footer from "layouts/containers/Footer";

export default function Report() {
  const [commentsOpen, setCommentsOpen] = useState(false);
  const { id, tab } = useParams();

  const navigate = useNavigate();

  useEffect(() => {
    setSection(tab);
  }, [tab]);

  // Report data management
  // Suggestion: Impelement tanstack query - https://tanstack.com/query/latest
  const { getReport } = useReports();
  const { report, setReport, section, setSection } = useReportContext();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const reportData = await getReport(id);
        if (reportData === undefined) {
          navigate("/");
        }
        setReport(reportData);
        setLoading(false);
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, [id]);

  //

  const toggleComments = (value) => {
    setCommentsOpen(value ? value : !commentsOpen);
  };

  const brands = (() => {
    if (!report["cp_comp_rankings"]) {
      return ["", "", "", "", ""];
    }

    return Object.keys(report["cp_comp_rankings"][0]).filter(
      (e) => e !== "category"
    );
  })();

  const sections = generateSections({ report, brands });
  const currentSection = sections.find((s) => s.id === section);

  return (
    <>
      <NavigationScrollListener
        className={`report-container ${currentSection.className ?? ""}`}
      >
        <div className="report-inner">
          <section className="report-hero">
            <div className="report-hero__heading">
              <div className="report-hero__heading-title">
                <h1 className="report-hero__heading-title">
                  {currentSection?.title}
                </h1>
                <Icon icon="information_line" />
              </div>
              <Button
                className="report-hero__heading-comments"
                iconBefore="chat_1_line"
                variant="secondary"
                onClick={toggleComments}
              >
                Report Commentary
              </Button>
            </div>
            <div className="report-hero__description">
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer
                ultricies nisl eget imperdiet hendrerit. Donec vehicula dolor at
                quam fringilla convallis. In et lobortis neque, nec sodales ipsum.
              </p>
            </div>
          </section>
          <section className="report-view">
            <div className={"report-view__data"}>
              {currentSection?.sections?.map((block, i) => {
                return (
                  <Block
                    key={i}
                    sectionData={block}
                    report={report}
                    loading={loading}
                  />
                );
              })}
            </div>
          </section>
        </div>
        <Footer />
      </NavigationScrollListener>
      <CommentsPanel isOpen={commentsOpen} onClose={toggleComments} />
    </>
  );
}
