import { useState } from "react";
import Button from "./Button";
import Form from "./Form";
import Icon from "components/Icon";
import logo from "assets/images/architect-icon.svg";

export default function Modal({
  type = "welcome",
  size = "normal",
  isOpen = false,
  closeModal,
  clients,
}) {
  const [isLoading, setIsLoading] = useState(false);

  const handleBackgroundClick = (e) => {
    if (e.target === e.currentTarget) {
      closeModal();
    }
  };

  return isOpen && (
    type === "input" ? (
      <div className={"full-page-cover "} onClick={handleBackgroundClick}>
        <div
          className={(
            "new-report-popup modal " +
            (isLoading ? " loading" : "") +
            (size === "normal" ? " full-width" : "small")
          ).trim()}
        >
          <div className="modal-inner">
            <div className="modal-header small">
              <img className="modal-logo" src={logo} alt="architect"></img>
            </div>
            <Form
              clients={clients}
              closeModal={closeModal}
            />
          </div>
        </div>
      </div>
    ) : (
      <div className={"full-page-cover "}>
        <Button
          iconBefore={"close_circle_line"}
          className={"close-button"}
          onClick={closeModal}
        ></Button>
        <div
          className={(
            "welcome-popup modal " +
            (isLoading ? " loading" : "") +
            (size === "normal" ? " full-width" : "small")
          ).trim()}
        >
          <div className="modal-inner">
            <div className="modal-header">
              <img className="modal-logo" src={logo} alt="architect"></img>
            </div>
            <div className="modal-main">
              <div className="modal-title">
                <h2>Welcome</h2>
                <p>to Architect at dentsu</p>
              </div>
              <p>
                Lorem ipsum dolor sit amet consectetur. Sed consectetur tristique
                ornare rutrum rhoncus scelerisque quis. Fermentum ut non cras
                phasellus congue cursus magna. Condimentum placerat id senectus
                odio gravida ipsum at integer. Rhoncus commodo congue proin
                egestas accumsan cursus. Quam elit donec pellentesque lobortis
                pellentesque ultrices.
              </p>
              <p className="support-link">
                Visit the{" "}
                <a href="" className="modal-link" title="">
                  Architect Support Channel
                </a>{" "}
                for FAQs and User Guides
              </p>
            </div>
          </div>
        </div>
      </div>
    )
  );
}
