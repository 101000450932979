import { performanceDashboard } from "./performanceDashboard";
import { revenueTrafficOpportunity } from "./revenueTrafficOpportunity";
import { uxDashboard } from "./uxDashboard";
import { competitorPerformance } from "./competitorPerformance";
// import { howCustomersSearch } from "./howCustomersSearch";
import { featureFormat } from "./featureFormat";

function findBlockRecursive(arr, id) {
  for (let i = 0; i < arr.length; i++) {
    const item = arr[i];

    if (item.id === id) {
      return item;
    }

    // Search multiblocks
    if (item?.multiBlock?.items) {
      const result = findBlockRecursive(item.multiBlock.items, id);
      if (result) {
        return result;
      }
    }
  }

  return null;
}

export const reportOverview = (props) => {
  const performanceDashboardBlock = performanceDashboard(props).find(
    (section) => section.id === "pd_traffic_opps"
  );
  const revenueTrafficOpportunityBlock = findBlockRecursive(
    revenueTrafficOpportunity(props),
    "rto_traffic_category"
  );

  const uxDashboardBlock = uxDashboard(props).find(
    (section) => section.id === "ux_category_tbe"
  );

  const competitorPerformanceBlock = competitorPerformance(props).find(
    (section) => section.id === "cp_traffic"
  );

  const featureFormatBlock = featureFormat(props).find(
    (section) => section.id === "ff_type"
  );

  return [
    {
      headingBlock: {
        title: "Performance Dashboard Overview",
      },
    },
    {
      paragraphBlock: {
        text: "Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content. Lorem ipsum may be used as a placeholder before the final copy is available.",
      },
    },
    {
      ...performanceDashboardBlock,
      className: "primary-pink",
    },

    {
      headingBlock: {
        title: "Revenue & Traffic Overview",
      },
    },
    {
      paragraphBlock: {
        text: "Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content. Lorem ipsum may be used as a placeholder before the final copy is available.",
      },
    },
    {
      ...revenueTrafficOpportunityBlock,
      chartBlock: {
        ...revenueTrafficOpportunityBlock.chartBlock,
        container: true,
      },
    },

    {
      headingBlock: {
        title: "UX Dashboard Overview",
      },
    },
    {
      paragraphBlock: {
        text: "Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content. Lorem ipsum may be used as a placeholder before the final copy is available.",
      },
    },
    {
      ...uxDashboardBlock,
      className: "primary-turquoise",
    },

    {
      headingBlock: {
        title: "Competitor Performance Overview",
      },
    },
    {
      paragraphBlock: {
        text: "Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content. Lorem ipsum may be used as a placeholder before the final copy is available.",
      },
    },
    {
      ...competitorPerformanceBlock,
      className: "primary-yellow",
    },

    // {
    //   headingBlock: {
    //     title: "How Customers Search Overview",
    //   },
    // },
    // {
    //   paragraphBlock: {
    //     text: "This block is not implemented yet.",
    //     style: { color: "red" },
    //   },
    // },

    {
      headingBlock: {
        title: "Feature Format Overview",
      },
    },
    {
      paragraphBlock: {
        text: "Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content. Lorem ipsum may be used as a placeholder before the final copy is available.",
      },
    },
    {
      ...featureFormatBlock,
      className: "primary-green",
    },
  ];
};
