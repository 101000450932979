import { useEffect, useState } from "react";
import {
  ResponsiveContainer,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  Bar,
  BarChart,
} from "recharts";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Skeleton } from "primereact/skeleton";
import Icon from "components/Icon";
import ReportItem from "../ReportItem";

const ConditionalContainer = ({
  container,
  id,
  title,
  children,
  className,
}) => {
  if (container) {
    return (
      <ReportItem id={id} className={className}>
        <div className="report-item__heading">
          <h2 className="report-item__heading-title">{title}</h2>
        </div>
        <div className="report-item__data">{children}</div>
      </ReportItem>
    );
  }

  return <>{children}</>;
};

export default function TableBlock({
  id,
  title,
  data,
  cols,
  colors = [],
  loading,
  sortField,
  sortOrder,
  footer,
  fields,
  container,
  className,
  swap = false
}) {
  const formatNum = (num) => {
    return num.toLocaleString("en-US");
  };

  return (
    <ConditionalContainer
      container={container}
      id={id}
      title={title}
      className={className}
    >
      <div className={"report-item__data-table " + (swap ? "swap-order" :"")}>
        <DataTable
          value={loading ? Array.from({ length: 5 }, (v, i) => i) : data}
          sortField={sortField}
          sortOrder={sortOrder}
          sortIcon={(options) =>
            options.sorted ? (
              options.sortOrder === 1 ? (
                <Icon icon="up_small_line" />
              ) : (
                <Icon icon="down_small_line" />
              )
            ) : (
              <Icon icon="selector_vertical_line" />
            )
          }
        >
          {cols.map((col, i) => (
            <Column
              headerStyle={{
                backgroundColor: colors[i] ?? "#000",
                borderColor: colors[i] ?? "#000",
              }}
              key={i}
              field={fields[i]}
              header={col}
              sortable
              footer={footer ? footer[i] : ""}
              body={
                loading ? (
                  <Skeleton />
                ) : (
                  (rowData) =>
                    Number.isFinite(rowData[fields[i]])
                      ? formatNum(rowData[fields[i]])
                      : rowData[fields[i]]
                )
              }
            />
          ))}
        </DataTable>
      </div>
    </ConditionalContainer>
  );
}
