import { useEffect, useState } from "react";
import {
  ResponsiveContainer,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  Bar,
  BarChart,
} from "recharts";
import ReportItem from "../ReportItem";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Skeleton } from "primereact/skeleton";
import Icon from "components/Icon";
import TableBlock from "./TableBlock";

function Wrapper({ children, container, ...other }) {
  if (container === false) return <div {...other}>{children}</div>;
  return <ReportItem {...other}>{children}</ReportItem>;
}

export default function ChartBlock({
  id,
  title,
  data,
  cols,
  colors = [],
  loading,
  sortField,
  sortOrder,
  footer,
  fields,
  container = true,
  className,
  swap
}) {
  const chartOptions = {
    indexAxis: "y",
    interaction: {
      intersect: false,
      mode: "nearest",
      axis: "y",
    },
    scales: {
      y: {
        beginAtZero: true,
        grid: {
          display: false,
        },
      },
      x: {
        display: false,
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        backgroundColor: "rgba(0,0,0,0)",
        bodyColor: "#000",
        yAlign: "center",
        xAlign: "left",
        callbacks: {
          title: function (context) {
            return "";
          },
        },
      },
    },
  };

  let [barChartData, setBarChartData] = useState({});

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div className="chart-tooltip">
          <p className="chart-label">{`${payload[0].value}`}</p>
        </div>
      );
    }
  };

  const formatNum = (num) => {
    return num.toLocaleString();
  };

  const sortData = (data, field, order) => {
    if (order === 1) {
      return data.sort((a, b) => a[field] - b[field]);
    }
    else {
      return data.sort((a, b) => b[field] - a[field]);
    }
  };

  return (
    <Wrapper id={id} container={container} className={className}>
      <div className="report-item__heading">
        <h2 className="report-item__heading-title">{title}</h2>
      </div>
      <div className="report-item__data showChart">
        <TableBlock
          data={data}
          cols={cols}
          colors={colors}
          fields={fields}
          sortField={sortField}
          sortOrder={sortOrder}
          footer={footer}
          loading={loading}
          container={false}
          swap={swap}
        />
        {data && (
          <div className="report-item__data-graph">
            <ResponsiveContainer width="100%" height="100%">
              <BarChart
                data={sortData(data, sortField, sortOrder)}
                layout="vertical"
                barSize={5}
                margin={{ top: 0, right: 50, bottom: 0, left: 40 }}
              >
                <CartesianGrid stroke="#E5E5E9" horizontal={false} />
                <YAxis
                  type="category"
                  dataKey={fields[0]}
                  tickLine={false}
                  stroke="#000"
                />
                <XAxis
                  type="number"
                  dataKey={sortField}
                  tickFormatter={(value) => value && value.toLocaleString()}
                />
                <Bar
                  dataKey={sortField}
                  fill={colors[cols.indexOf(sortField)] ?? "var(--primary)"}
                  radius={[0, 10, 10, 0]}
                  activeBar={{ fill: "#9747FF" }}
                  label={{ position: "right", formatter: formatNum }}
                />
              </BarChart>
            </ResponsiveContainer>
          </div>
        )}
      </div>
    </Wrapper>
  );
}
