import { useEffect, useRef, useState } from "react";
import Button from "components/Button";
import SidePanel from "components/SidePanel";
import useComponentVisible from "hooks/useComponentVisible";
import { Editor } from 'primereact/editor';
import { useReportContext } from "context/ReportContext";
import useReports from "services/ReportService";

export default function CommentsPanel({ isOpen, onClose }) {
  const { report } = useReportContext();
  const [commentary, setCommentary] = useState("");
  const [readOnly, setReadOnly] = useState(true);
  const { updateCommentary } = useReports();

  const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false);

  useEffect(() => {
    setIsComponentVisible(isOpen);
  }, [isOpen, setIsComponentVisible]);


  useEffect(() => {
    setCommentary(report.commentary);
  }, [report]);

  const handleClose = () => {
    setCommentary(report.commentary);
    onClose(!isOpen);
  };

  const handleApply = async () => {
    // update on server

    const response = await updateCommentary({"id": report.id, "commentary": commentary});
    // if (response.status == 200) {
    //   // fetchData();
    //   // showToast({ severity: 'info', summary: 'Confirmed', detail: 'You have accepted', life: 3000 });
    // }
    // else {
    //   setCommentary(report.commentary);
    // }
    setReadOnly(true);
    quill.current.getQuill().disable();
  };

  const handleCancel = () => {
    quill.current.getQuill().disable();
    setCommentary(report.commentary);
    setReadOnly(true);
  };

  const handleEdit = () => {
    setReadOnly(false);
    quill.current.getQuill().enable();
  };

  const renderHeader = () => {
    return (
      <span className="ql-formats">
        <select className="ql-header"></select>
        <button className="ql-bold" aria-label="Bold"></button>
        <button className="ql-italic" aria-label="Italic"></button>
        <button className="ql-underline" aria-label="Underline"></button>
      </span>
    );
  };

  const header = renderHeader();

  const quill = useRef()

  return (
    isOpen && (
      <SidePanel
        className="report-comments"
        isOpen={isComponentVisible}
        reference={ref}
        heading="Commentary"
        // title="Performance Dashboard"
        onClose={handleClose}
        variant="compact"
      >
        <Editor
          value={commentary}
          onTextChange={(e) => setCommentary(e.htmlValue)}
          formats={['header', 'bold', 'italic', 'underline', 'strike']}
          headerTemplate={header}
          readOnly={true}
          className={readOnly ? "readOnly" : ""}
          ref={quill}
        />
        <div className="report-comments__actions">
          {!readOnly && (
            <>
              <Button variant="secondary" onClick={handleCancel}>Cancel</Button>
              <Button onClick={handleApply}>Apply changes</Button>
            </>
          )}
          {readOnly && (
            <Button onClick={handleEdit}>Edit</Button>
          )}
        </div>
      </SidePanel>
    )
  );
}
