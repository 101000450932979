import { reportOverview } from "./reportOverview";
import { performanceDashboard } from "./performanceDashboard";
import { revenueTrafficOpportunity } from "./revenueTrafficOpportunity";
import { uxDashboard } from "./uxDashboard";
import { competitorPerformance } from "./competitorPerformance";
import { howCustomersSearch } from "./howCustomersSearch";
import { featureFormat } from "./featureFormat";

export const sections = (props) => [
  {
    id: "report-overview",
    title: "Report Overview",
    sections: reportOverview(props),
    className: "report-overview-section",
  },
  {
    id: "performance-dashboard",
    title: "Performance Dashboard",
    sections: performanceDashboard(props),
    className: "primary-pink",
  },
  {
    id: "revenue-traffic-opportunity",
    title: "Revenue & Traffic Opportunity",
    sections: revenueTrafficOpportunity(props),
    className: "primary-purple",
  },
  {
    id: "ux-dashboard",
    title: "UX Dashboard",
    sections: uxDashboard(props),
    className: "primary-turquoise",
  },
  {
    id: "competitor-performance",
    title: "Competitor Performance",
    sections: competitorPerformance(props),
    className: "primary-yellow",
  },
  {
    id: "how-customers-search",
    title: "How Customers Search",
    sections: howCustomersSearch(props),
    className: "primary-blue",
  },
  {
    id: "feature-format",
    title: "Feature Format",
    sections: featureFormat(props),
    className: "primary-green",
  },
];
