import { useEffect, useState } from "react";
import Button from "components/Button";
import Icon from "components/Icon";
import SidePanel from "components/SidePanel";
import { Dropdown } from 'primereact/dropdown';
import { MultiSelect } from 'primereact/multiselect';
import { Calendar } from 'primereact/calendar';
import { addLocale } from 'primereact/api';

export default function FilterPanel({ filterOptions, fetchData, filterPanelOpen, toggleFilterPanel }) {
  const [client, setClient] = useState([]);
  const [dateStart, setDateStart] = useState(null);
  const [dateEnd, setDateEnd] = useState(null);

  addLocale('en', {
    firstDayOfWeek: 1,
  });

  const handleReset = () => {
    setClient([]);
    setDateStart(null);
    setDateEnd(null);
  };

  const handleClose = () => {
    handleReset();
    toggleFilterPanel();
  };

  const handleApply = () => {
    saveFiltersToLocalStorage();
    fetchData({
      ...((client && client !== "[]") && { "client": client }),
      ...(dateStart && { "dateStart": new Date(dateStart).toISOString().split('T')[0] }),
      ...(dateEnd && { "dateEnd": new Date(dateEnd).toISOString().split('T')[0] })
    })
  };
  
  const saveFiltersToLocalStorage = () => {
    localStorage.setItem('client', client == [] ? "" : JSON.stringify(client));
    localStorage.setItem('dateStart', dateStart == null ? "" : dateStart.toISOString().split('T')[0]);
    localStorage.setItem('dateEnd',  dateEnd == null ? "" : dateEnd.toISOString().split('T')[0]);
  };

  const loadFilters = () => {
    setClient(localStorage.getItem('client') === "" ? [] : JSON.parse(localStorage.getItem('client')));
    setDateStart(localStorage.getItem('dateStart') ? new Date(localStorage.getItem('dateStart')) : undefined);
    setDateEnd(localStorage.getItem('dateEnd') ? new Date(localStorage.getItem('dateEnd')) : undefined);
  };
  
  useEffect(() => {
    loadFilters();
  }, []);

  return (
    filterPanelOpen && (
      <SidePanel
        className="report-filters"
        isOpen={filterPanelOpen}
        title="Filter Reports"
        onClose={handleClose}
      >
        <div className="report-filters__filter-list">
          <div className="report-filters__filter">
            <label htmlFor="filter-client">Client</label>
            <div className="report-filters__filter-input relative">
              <MultiSelect inputId="filter-client" value={client} onChange={(e) => setClient(e.value)} showClear
                options={filterOptions.client} placeholder="Select Client" maxSelectedLabels={1} />
            </div>
          </div>
          <div className="report-filters__filter">
            <label>Date Report Created</label>
            <Calendar value={dateStart} onChange={(e) => setDateStart(e.value)} placeholder={"Date From"}
              maxDate={new Date() < dateEnd ? new Date() : dateEnd} dateFormat={"dd/mm/yy"} showButtonBar />
            <Calendar value={dateEnd} onChange={(e) => setDateEnd(e.value)} placeholder={"Date To"}
              minDate={dateStart} maxDate={new Date()} dateFormat={"dd/mm/yy"} showButtonBar/>
          </div>
        </div>
        <div className="report-filters__actions">
          <Button variant="secondary" onClick={handleReset}>Reset</Button>
          <Button onClick={handleApply}>Apply</Button>
        </div>
      </SidePanel>
    )
  );
}
