import { useRef } from "react";
import useComponentVisible from "hooks/useComponentVisible";
// import Button from "components/Button";
import Icon from "components/Icon";
import { Link } from "react-router-dom";
import { confirmDialog } from 'primereact/confirmdialog'; // For confirmDialog method
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import useReports from "services/ReportService";
import { useToastContext } from "components/Toast";


// import "primereact/resources/themes/lara-light-cyan/theme.css";

export default function EditControls({ item, fetchData }) {
  const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false);
  const toggleContextMenu = () => {
    setIsComponentVisible(!isComponentVisible);
  };

  const { deleteReport } = useReports();

  const { showToast } = useToastContext();



  const accept = async () => {
    const response = await deleteReport(item)
    if (response.status == 200) {
      fetchData();
      showToast({ severity: 'info', summary: 'Confirmed', detail: 'You have accepted', life: 3000 });
    }
    else {
    // toast.current.show({ severity: 'warn', summary: 'Rejected', detail: 'You have rejected', life: 3000 });
    }
  }

  // const reject = () => {
  //     toast.current.show({ severity: 'warn', summary: 'Rejected', detail: 'You have rejected', life: 3000 });
  // }

  

  const confirm2 = () => {
      confirmDialog({
          message: 'Do you want to delete this record?',
          header: 'Delete Confirmation',
          icon: 'pi pi-info-circle',
          defaultFocus: 'reject',
          acceptClassName: 'p-button-danger',
          accept,
          // reject
      });
  };

  return (
    <div className="context relative" ref={ref}>
      {/* <Button
        className="context__button"
        variant="edit"
        iconBefore="more_1_line"
        onClick={toggleContextMenu}
      /> */}
      <button type="button" className="btn btn--edit context__button btn-icon" onClick={toggleContextMenu}><span className="mgc_more_1_line icon"></span></button>
      <div className={"context__menu" + (isComponentVisible ? " open" : "")}>
        {/* <Link to={`/report/${item.id}/edit`} className="context__menu-item">
          <Icon icon="edit_2_line" />
          Edit Details
        </Link>
        <Link to={`/report/${item.id}/download`} className="context__menu-item">
          <Icon icon="download_2_line" />
          Download .xlsx
        </Link>
        <Link
          to={`/report/${item.id}/duplicate`}
          className="context__menu-item"
        >
          <Icon icon="copy_2_line" />
          Duplicate
        </Link> */}
        {/* <Link
          // to={`/report/${item.id}/delete`}
          to={``}
          className="context__menu-item context__menu-item--delete"
        >
          <Icon icon="delete_2_line" />
          Delete
        </Link> */}
        <button type="button" className="btn context__menu-item context__menu-item--delete" onClick={confirm2}><span className="mgc_delete_2_line icon"></span>Delete</button>
        {/* <div className="card flex flex-wrap gap-2 justify-content-center">
          <Button onClick={confirm2} icon="pi pi-times" label="Delete"></Button>
        </div> */}
      </div>
    </div>
  );
}
