import { getTotal } from "./utils";

export const howCustomersSearch = ({ report }) => [
  {
    id: "hcs_searches",
    tableBlock: {
      title: "Total Monthly Searches",
      cols: ["Websites", "Est. Current Clicks", "% share"],
      sortField: "col2",
      sortOrder: -1,
      container: true,
    },
  },
  {
    headingBlock: {
      id: "categories-searches-breakdown",
      title: "Categories Searches Breakdown",
    },
  },
  {
    id: "hcs_cat_break",
    chartBlock: {
      cols: ["Category", "Avg Monthly Volume"],
      fields: ["category", "volume"],
      sortField: "volume",
      sortOrder: -1,
      footer: [
        "Grand Total",
        getTotal({ report, sheet: "hcs_cat_break", field: "volume" }),
      ],
    },
  },
  {
    id: "hcs_sub_cat_break",
    chartBlock: {
      cols: ["Category", "Avg Monthly Volume"],
      fields: ["category", "volume"],
      sortField: "volume",
      sortOrder: -1,
      footer: [
        "Grand Total",
        getTotal({ report, sheet: "hcs_sub_cat_break", field: "volume" }),
      ],
    },
  },
  {
    id: "hcs_location_break",
    chartBlock: {
      cols: ["Category", "Avg Monthly Volume"],
      fields: ["category", "volume"],
      sortField: "volume",
      sortOrder: -1,
      footer: [
        "Grand Total",
        getTotal({ report, sheet: "hcs_location_break", field: "volume" }),
      ],
    },
  },
  {
    headingBlock: {
      id: "categories-searches-yoy",
      title: "Categories Searches Year On Year",
    },
  },
  {
    id: "hcs_cat_yoy",
    tableBlock: {
      cols: ["Category", "Last 12 Months", "Previous 12 Months", "YOY Change"],
      fields: ["category", "last", "prev", "yoy"],
      sortField: "prev",
      sortOrder: -1,
      footer: [
        "Grand Total",
        getTotal({ report, sheet: "hcs_cat_yoy", field: "last" }),
        getTotal({ report, sheet: "hcs_cat_yoy", field: "prev" }),
      ],
      container: true,
    },
  },
  {
    id: "hcs_sub_cat_yoy",
    tableBlock: {
      cols: ["Category", "Last 12 Months", "Previous 12 Months", "YOY Change"],
      fields: ["category", "last", "prev", "yoy"],
      sortField: "prev",
      sortOrder: -1,
      footer: [
        "Grand Total",
        getTotal({ report, sheet: "hcs_sub_cat_yoy", field: "last" }),
        getTotal({ report, sheet: "hcs_sub_cat_yoy", field: "prev" }),
      ],
      container: true,
    },
  },
  {
    id: "hcs_location_yoy",
    tableBlock: {
      cols: ["Category", "Last 12 Months", "Previous 12 Months", "YOY Change"],
      fields: ["category", "last", "prev", "yoy"],
      sortField: "prev",
      sortOrder: -1,
      footer: [
        "Grand Total",
        getTotal({ report, sheet: "hcs_location_yoy", field: "last" }),
        getTotal({ report, sheet: "hcs_location_yoy", field: "prev" }),
      ],
      container: true,
    },
  },
];
