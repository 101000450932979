import React from "react";

export default function CustomTooltip({ active, payload, label, barField }) {
  if (active && payload && payload.length) {
    const barPayload = payload.find((item) => item.dataKey === barField);

    return (
      <div className="custom-tooltip">
        <p className="custom-tooltip__title">{label}</p>
        {!!barPayload && (
          <p className="custom-tooltip__bar-metric">
            {barPayload.name}: {barPayload.value}
          </p>
        )}

        <div
          className="custom-tooltip__stat-container"
          style={{
            gridTemplateColumns: `repeat(${payload.length - 1}, 1fr)`,
          }}
        >
          {payload.map((item, index) => {
            if (item.dataKey === barField) return null;

            return (
              <div key={index} className="custom-tooltip__stat">
                <div className="custom-tooltip__stat-bar-container">
                  <div
                    className="custom-tooltip__stat-bar"
                    style={{
                      height: `${item.value}%`,
                      backgroundColor: item.fill,
                    }}
                  ></div>
                </div>
                <p className="custom-tooltip__stat-name">{item.name}</p>
                <p className="custom-tooltip__stat-value">{item.value}%</p>
              </div>
            );
          })}
        </div>
      </div>
    );
  }

  return null;
}
