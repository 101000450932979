const performanceList = [
  {
    name: "Best Ranking Categories",
    key: "pd_best_ranking_categories",
  },
  {
    name: "Worst Ranking Categories",
    key: "pd_worst_ranking_categories",
  },
  {
    name: "Traffic Gain Opportunities",
    key: "pd_traffic_opps",
  },
  {
    name: "Biggest Revenue Gain Opportunities",
    key: "pd_revenue_opps",
  },
  {
    name: "Keyword Ranking Opportunities",
    key: "pd_ranking_opps",
  },
  {
    name: "Keywords Ranking Per URL",
    key: "pd_url_ranking",
  },
  {
    name: "Biggest Searched for Categories",
    key: "pd_biggest_search_categories",
  },
  {
    name: "Keywords Intent",
    key: "pd_keyword_intent",
  },
  {
    name: "Keywords Page Template",
    key: "pd_page_template",
  },
];

const revenueList = [
  {
    name: "Traffic Opportunities",
    key: "traffic-opportunities",
  },
  {
    name: "Revenue Opportunities",
    key: "revenue-opportunities",
  },
];

const uxList = [
  {
    name: "Category: Traffic, Bounce Rate, Exit Rate",
    key: "ux_category_tbe",
  },
  {
    name: "Category: Traffic, CVR",
    key: "ux_category_tcvr",
  },
  {
    name: "Page Template: Traffic, Bounce Rate, Exit Rate",
    key: "ux_template_tbe",
  },
  {
    name: "Page Template: Traffic, CVR",
    key: "ux_template_tcvr",
  },
];

const competitorList = [
  {
    name: "Share of Estimated Traffic",
    key: "cp_traffic",
  },
  {
    name: "Keyword Gaps",
    key: "cp_keyword_gaps",
  },
  {
    name: "Competitor Rankings",
    key: "cp_comp_rankings",
  },
  {
    name: "Competitor Estimated Traffic",
    key: "cp_comp_traffic",
  },
  {
    name: "Competitor Estimated revenue",
    key: "cp_comp_revenue",
  },
];

const hcsList = [
  // {
  //   name: "Total Monthly Searches",
  //   key: "hcs_searches"
  // },
  {
    name: "Categories Searches Breakdown",
    key: "categories-searches-breakdown",
  },
  {
    name: "Categories Searches Year On Year",
    key: "categories-searches-yoy",
  },
];

const ffList = [
  {
    name: "SERP Feature Result Type",
    key: "ff_type",
  },
  {
    name: "Owned SERP Features",
    key: "ff_owned",
  },
  {
    name: "Top SERP Features Owned By Competitor",
    key: "ff_competitor",
  },
];

export const sideNavLinks = [
  {
    title: "Report Overview",
    type: "jump",
    url: "report-overview",
    icon: "presentation_1_line",
    subList: [],
    className: "report-overview-link",
  },
  {
    title: "Performance Dashboard",
    type: "jump",
    url: "performance-dashboard",
    icon: "chart_line_line",
    subList: performanceList,
    className: "primary-pink",
    filterSubList: true,
    hideIfSubListEmpty: true,
  },
  {
    title: "Revenue & Traffic Opportunity",
    type: "jump",
    url: "revenue-traffic-opportunity",
    icon: "currency_pound_line",
    subList: revenueList,
    className: "primary-purple",
  },
  {
    title: "UX Dashboard",
    type: "jump",
    url: "ux-dashboard",
    icon: "desk_lamp_2_line",
    subList: uxList,
    className: "primary-turquoise",
    filterSubList: true,
    hideIfSubListEmpty: true,
  },
  {
    title: "Competitor Performance",
    type: "jump",
    url: "competitor-performance",
    icon: "presentation_1_line",
    subList: competitorList,
    className: "primary-yellow",
    filterSubList: true,
    hideIfSubListEmpty: true,
  },
  {
    title: "How Customers Search",
    type: "jump",
    url: "how-customers-search",
    icon: "user_search_line",
    subList: hcsList,
    className: "primary-blue",
    filterSubList: false,
    hideIfSubListEmpty: false,
  },
  {
    title: "Feature Format",
    type: "jump",
    url: "feature-format",
    icon: "polygon_line",
    subList: ffList,
    className: "primary-green",
    filterSubList: true,
    hideIfSubListEmpty: true,
  },
];
