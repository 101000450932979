import Footer from "./containers/Footer";
import Navbar from "./containers/Navbar";

export default function VerticalLayout({ children }) {
  return (
    <div className="wrapper">
      <Navbar />
      <main>
        <div className="container">{children}</div>
      </main>
      <Footer />
    </div>
  );
}
