import React from "react";

import PvMixChartBlock from "./PvMixChartBlock";
import ParagraphBlock from "./ParagraphBlock";
import HeadingBlock from "./HeadingBlock";
import MultiBlock from "./MultiBlock";
import ChartBlock from "./ChartBlock";
import TableBlock from "./TableBlock";

export default function Block({ sectionData, report, loading }) {
  if (sectionData.pvMixChartBlock) {
    if (!report[sectionData.id]) {
      return null;
    }

    return (
      <PvMixChartBlock
        className={sectionData.className}
        id={sectionData.id}
        data={report[sectionData.id]}
        loading={loading}
        {...sectionData.pvMixChartBlock}
      />
    );
  }

  if (sectionData.chartBlock) {
    if (!report[sectionData.id]) {
      return null;
    }

    return (
      <ChartBlock
        className={sectionData.className}
        id={sectionData.id}
        data={report[sectionData.id]}
        loading={loading}
        {...sectionData.chartBlock}
      />
    );
  }

  if (sectionData.tableBlock) {
    if (!report[sectionData.id]) {
      return null;
    }

    return (
      <TableBlock
        className={sectionData.className}
        id={sectionData.id}
        data={report[sectionData.id]}
        loading={loading}
        {...sectionData.tableBlock}
      />
    );
  }

  if (sectionData.multiBlock) {
    return (
      <MultiBlock
        className={sectionData.className}
        {...sectionData.multiBlock}
        report={report}
        loading={loading}
      />
    );
  }

  if (sectionData.headingBlock) {
    return (
      <HeadingBlock
        className={sectionData.className}
        {...sectionData.headingBlock}
      />
    );
  }

  if (sectionData.paragraphBlock) {
    return (
      <ParagraphBlock
        className={sectionData.className}
        {...sectionData.paragraphBlock}
      />
    );
  }

  return null;
}
