import React from "react";
import ReportItem from "../ReportItem";
import Block from "./Block";

export default function MultiBlock({
  items,
  report,
  loading,
  title,
  ...other
}) {
  return (
    <ReportItem {...other}>
      {title !== undefined && (
        <div className="report-item__heading">
          <h2 className="report-item__heading-title">{title}</h2>
        </div>
      )}
      {items.map((item, index) => (
        <Block
          key={item.id ?? index}
          sectionData={item}
          report={report}
          loading={loading}
        />
      ))}
    </ReportItem>
  );
}
