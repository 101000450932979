import Icon from "components/Icon";
import { Link } from "react-router-dom";

export default function Button({
  alt,
  children,
  className,
  disabled = false,
  href,
  iconBefore = "",
  iconAfter,
  loading = false,
  onClick,
  size = "normal",
  to,
  type = "button",
  variant = "primary",
  style
}) {
  return type === "link" || type === "router-link" ? (
    type === "router-link" ? (
      <Link
        alt={alt}
        onClick={onClick && onClick}
        to={to}
        className={(
          "link " +
          (className ?? "") +
          (loading ? " loading" : "") +
          (size === "large" ? " full-width" : "")+
          (iconBefore && " btn-icon")
        ).trim()}
        disabled={disabled}
      >
        {iconBefore && <Icon icon={iconBefore} />}
        <span>{children}</span>
        {iconAfter && <Icon icon={iconAfter} />}
      </Link>
    ) : (
      <a
        alt={alt}
        onClick={onClick && onClick}
        href={href}
        className={(
          "link " +
          `link--${variant} ` +
          (className ?? "") +
          (loading ? " loading" : "") +
          (size === "large" ? " full-width" : "") +
          (iconBefore && "btn-icon")
        ).trim()}
        disabled={disabled}
      >
        {iconBefore && <Icon icon={iconBefore} />}
        <span>{children}</span>
        {iconAfter && <Icon icon={iconAfter} />}
      </a>
    )
  ) : (
    <button
      alt={alt}
      onClick={onClick && onClick}
      type={type}
      style={style}
      className={(
        "btn " +
        `btn--${variant} ` +
        (className ?? "") +
        (loading ? " loading" : "") +
        (size === "large" ? " full-width" : "") +
        (iconBefore && " btn-icon")
      ).trim()}
      disabled={disabled}
    >
      {iconBefore && <Icon icon={iconBefore} />}
      {children && <span className="btn__text">{children}</span>}
      {iconAfter && <Icon icon={iconAfter} />}
    </button>
  );
}
