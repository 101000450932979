import Button from "./Button";

export default function SidePanel({
  children,
  className,
  heading,
  isOpen,
  onClose,
  title,
  variant,
}) {
  switch (variant) {
    case "compact":
      return (
        <div
          className={(
            "side-panel compact" +
            (isOpen ? " open" : "") +
            (className ? ` ${className}` : "")
          ).trim()}
        >
          <div className="side-panel__header">
            <h3 className="side-panel__header-heading">{heading}</h3>
            <Button
              alt={`Close ${title} side panel`}
              className="side-panel__header-close"
              iconBefore="close_circle_line"
              variant="basic"
              onClick={onClose}
            />
            {title && (
              <h2 className="side-panel__header-title">{title}</h2>
            )}
          </div>
          <div className="side-panel__content">
            <div
              className={(
                (className || "") + " side-panel__content-wrapper"
              ).trim()}
            >
              {children}
            </div>
          </div>
        </div>
      );
    default:
      return (
        <div
          className={(
            "side-panel" +
            (isOpen ? " open" : "") +
            (className ? ` ${className}` : "")
          ).trim()}
        >
          <div className="side-panel__header">
            <h1 className="side-panel__header-heading">{title}</h1>
            <Button
              alt={`Close ${title} side panel`}
              className="side-panel__header-close"
              iconBefore="close_circle_line"
              variant="basic"
              onClick={onClose}
            />
          </div>
          <div className="side-panel__content">
            <div className=" side-panel__content-wrapper">
              {children}
            </div>
          </div>
        </div>
      );
  }
}
