import { getTotal } from "./utils";

export const performanceDashboard = ({ report, brands }) => [
  {
    id: "pd_best_ranking_categories",
    chartBlock: {
      title: "Best Ranking Categories",
      cols: ["Category", "Ranking"],
      fields: ["category", "ranking"],
      sortField: "ranking",
      sortOrder: 1,
      footer: [
        "Grand Total",
        getTotal({
          report,
          sheet: "pd_best_ranking_categories",
          field: "ranking",
        }),
      ],
    },
  },
  {
    id: "pd_worst_ranking_categories",
    chartBlock: {
      title: "Worst Ranking Categories",
      cols: ["Category", "Ranking"],
      fields: ["category", "ranking"],
      sortField: "ranking",
      sortOrder: -1,
      swap: true,
      footer: [
        "Grand Total",
        getTotal({
          report,
          sheet: "pd_worst_ranking_categories",
          field: "ranking",
        }),
      ],
    },
  },
  {
    id: "pd_traffic_opps",
    chartBlock: {
      title: "What are the biggest traffic gain opportunities?",
      cols: ["Category", "Yearly Traffic Gain In #1"],
      fields: ["category", "traffic_gain"],
      sortField: "traffic_gain",
      sortOrder: -1,
      footer: [
        "Grand Total",
        getTotal({ report, sheet: "pd_traffic_opps", field: "traffic_gain" }),
      ],
    },
  },
  {
    id: "pd_revenue_opps",
    chartBlock: {
      title: "What are the biggest revenue gain opportunities?",
      cols: ["Category", "Yearly Revenue Gain In #1"],
      fields: ["category", "revenue_gain"],
      sortField: "revenue_gain",
      sortOrder: -1,
      swap: true,
      footer: [
        "Grand Total",
        getTotal({ report, sheet: "pd_revenue_opps", field: "revenue_gain" }),
      ],
    },
  },
  {
    id: "pd_ranking_opps",
    chartBlock: {
      title: "How are keyword ranking opportunities grouped?",
      cols: ["Category", "Opportunity Breakdown"],
      fields: ["category", "opp"],
      sortField: "opp",
      sortOrder: -1,
      footer: [
        "Grand Total",
        getTotal({ report, sheet: "pd_ranking_opps", field: "opp" }),
      ],
    },
  },
  {
    id: "pd_url_ranking",
    tableBlock: {
      title: "Keywords Ranking Per URL (Top 10 Pages)",
      cols: ["Category", "Count of Ranking URL"],
      fields: ["category", "ranking"],
      sortField: "ranking",
      sortOrder: -1,
      footer: [
        "Grand Total",
        getTotal({ report, sheet: "pd_url_ranking", field: "ranking" }),
      ],
      container: true,
    },
  },
  {
    id: "pd_biggest_search_categories",
    chartBlock: {
      title: "What are the biggest searched for categories?",
      cols: ["Category", "Yearly Traffic Gain In #1"],
      fields: ["category", "volume"],
      sortField: "volume",
      sortOrder: -1,
      footer: [
        "Grand Total",
        getTotal({
          report,
          sheet: "pd_biggest_search_categories",
          field: "volume",
        }),
      ],
    },
  },
  {
    id: "pd_keyword_intent",
    tableBlock: {
      title: "How are the keywords broken down by intent?",
      cols: [
        "Intent",
        "Number of Keywords",
        "Est. Current Clicks",
        "Expected Yearly Traffic In #1",
      ],
      fields: ["intent", "keywords", "clicks", "traffic"],
      sortField: "traffic",
      sortOrder: -1,
      footer: [
        "Grand Total",
        getTotal({ report, sheet: "pd_keyword_intent", field: "keywords" }),
        getTotal({ report, sheet: "pd_keyword_intent", field: "clicks" }),
        getTotal({ report, sheet: "pd_keyword_intent", field: "traffic" }),
      ],
      container: true,
    },
  },
  {
    id: "pd_page_template",
    tableBlock: {
      title: "How are the keywords broken down by page template?",
      cols: [
        "Page Template",
        "Number of Keywords",
        "Est. Current Clicks",
        "Expected Yearly Traffic In #1",
      ],
      fields: ["page", "keywords", "clicks", "traffic"],
      sortField: "traffic",
      sortOrder: -1,
      footer: [
        "Grand Total",
        getTotal({ report, sheet: "pd_page_template", field: "keywords" }),
        getTotal({ report, sheet: "pd_page_template", field: "clicks" }),
        getTotal({ report, sheet: "pd_page_template", field: "traffic" }),
      ],
      container: true,
    },
  },
];
