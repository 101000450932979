import React from "react";

export default function HeadingBlock({ style, title, children, ...other }) {
  return (
    <h2
      style={{ ...style }}
      {...other}
      className={`report-block__heading ${other.className ?? ""}`}
    >
      {title}
      {children}
    </h2>
  );
}
