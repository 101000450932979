import React from "react";

export default function ParagraphBlock({
  className,
  children,
  text,
  ...other
}) {
  return (
    <h2 {...other} className={`report-block__paragraph ${className}`}>
      {text}
      {children}
    </h2>
  );
}
