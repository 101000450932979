import { useState, useEffect } from "react";
import { useIdleTimer } from 'react-idle-timer'
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { useOktaAuth } from '@okta/okta-react';

export default function RouterApp() {

  const { oktaAuth } = useOktaAuth();

  const timeout = 3_600_000
  const promptBeforeIdle = 60_000
  const [remaining, setRemaining] = useState(timeout)

  const onIdle = () => {
    oktaAuth.signOut();
    setVisible(false)
  }

  const onActive = () => {
    setVisible(false)
  }

  const onPrompt = () => {
    setVisible(true)
  }

  const { getRemainingTime, activate } = useIdleTimer({
    onIdle,
    onActive,
    onPrompt,
    timeout,
    promptBeforeIdle,
    throttle: 500
  })

  useEffect(() => {
    const interval = setInterval(() => {
      setRemaining(Math.ceil(getRemainingTime() / 1000))
    }, 500)

    return () => {
      clearInterval(interval)
    }
  })

  const handleStillHere = () => {
    setVisible(false)
    activate()
  }

  const [visible, setVisible] = useState(false);

  return (
    <Dialog visible={visible} modal onHide={() => setVisible(false)}
      header={<h3>Are you still here?</h3>}
      footer={<Button label="I'm still here" onClick={handleStillHere} autoFocus />}
    >
      <p>Logging out in {remaining} seconds</p>
    </Dialog>
  );
}
