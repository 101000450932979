import { OktaAuth, toRelativeUrl } from "@okta/okta-auth-js";
import { LoginCallback, Security } from "@okta/okta-react";
import { BrowserRouter, Route, Routes, useNavigate } from "react-router-dom";
import VerticalLayout from "layouts/VerticalLayout";
import HorizontalLayout from "layouts/HorizontalLayout/HorizontalLayout";
import ReportContextProvider from "context/ReportContext";
import Home from "pages/Home";
import Report from "pages/Report";
import Timeout from "components/Timeout";
import { Toast } from "primereact/toast";
import { ConfirmDialog } from "primereact/confirmdialog";
import { ToastContextProvider } from "components/Toast";
import Authenticated from "components/Authenticated";
import { SidebarContextProvider } from "context/SidebarContext";

let oktaDetails = {
  redirectUri: `${window.location.origin}/login/callback`,
};

if(process.env.NODE_ENV === "production") {
  oktaDetails.issuer = "https://di-hybrid.okta.com/oauth2/default	";
  oktaDetails.clientId = "0oahwv53p0UEdvaBR417";
}
else {
  oktaDetails.issuer = "https://dentsuaegis-test.okta-emea.com/oauth2/default";
  oktaDetails.clientId = "0oac4wkkh93KZ0TR90i7";
}


const oktaAuth = new OktaAuth(oktaDetails);

function App() {
  const navigate = useNavigate();
  const restoreOriginalUri = (_oktaAuth, originalUri) => {
    navigate(toRelativeUrl(originalUri || "/", window.location.origin));
  };
  return (
    <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
      <ToastContextProvider>
        <ConfirmDialog />
        {process.env.NODE_ENV === "production" && (
          <Timeout/>
        )}
        <Routes>
          <Route path="login/callback" element={<LoginCallback />} />
          <Route
            path="report/:id/:tab"
            element={
              <Authenticated
                success={
                  <ReportContextProvider>
                    <SidebarContextProvider>
                      <HorizontalLayout>
                        <Report />
                      </HorizontalLayout>
                    </SidebarContextProvider>
                  </ReportContextProvider>
                }
              />
            }
          />
          <Route
            path="*"
            element={
              <Authenticated
                success={
                  <VerticalLayout>
                    <Home />
                  </VerticalLayout>
                }
              />
            }
          />
        </Routes>
      </ToastContextProvider>
    </Security>
  );
}

const AppWithRouterAccess = () => (
  <BrowserRouter>
    <App />
  </BrowserRouter>
);

export default AppWithRouterAccess;
