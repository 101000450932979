import logo from "assets/images/dentsu-logo.png";

export default function Footer() {
  return (
    <footer>
      <div className="container">
        <a href="https://www.dentsu.com/uk/en/policies/responsible-disclosure-policy" target="_blank">Responsible Disclosure Policy</a>
        <a href="https://www.dentsu.com" target="_blank">
          <img className="logo" src={logo} alt="dentsu" />
        </a>
      </div>
    </footer>
  );
}
