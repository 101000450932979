import { Link, NavLink } from "react-router-dom";
import logo from "assets/images/architect-logo.svg";
import Icon from "components/Icon";
import { useOktaAuth } from '@okta/okta-react';
import Button from "components/Button";

export default function Navbar() {
  const { authState, oktaAuth } = useOktaAuth();

  const logout = async () => {
    await oktaAuth.signOut();
  };
  
  return (
    <header className="page-nav">
      <Link to="/" className="logo-link">
        <img className="logo-link__image" src={logo} alt="Architect Logo" />
      </Link>
      <nav>
        <ul>
          {/* <li>
            <NavLink to="/">
            <Icon icon="question_line" />
              Contact
            </NavLink>
          </li>
          <li>
            <NavLink to="/">
            <Icon icon="mail_line" />
              Support
            </NavLink>
          </li>
          <li>
            
          </li>
          <li>
            <div className="divider"></div>
          </li> */}
          <li className="profile">
            <div className="profile__picture">
              <Icon icon="user_3_line" />
            </div>
            <div className="profile__name">
              {authState.accessToken.claims.sub}
            </div>
          </li>
          <li>
            <Button
              className="logout"
              onClick={logout}
            >
              Logout
            </Button>
          </li>
        </ul>
      </nav>
    </header>
  );
}
