import React, {
  createContext,
  useContext,
  useMemo,
  useState,
  useEffect,
  useRef,
} from "react";
import { useReportContext } from "./ReportContext";
import { sideNavLinks } from "../layouts/HorizontalLayout/navConfig";

const SidebarContext = createContext(undefined);

export function SidebarContextProvider({ children }) {
  const { report } = useReportContext();

  const [highlightedSectionBlock, setHighlightedSectionBlock] = useState(null);
  const [navLinks, setNavLinks] = useState([]);
  const subNavLinkSet = useMemo(
    () =>
      new Set(
        navLinks.flatMap((link) => link.subList.map((subLink) => subLink.key))
      ),
    [navLinks]
  );

  useEffect(() => {
    setNavLinks(
      sideNavLinks.map((link) => ({
        ...link,
        subList: link.filterSubList
          ? link.subList.filter((subLink) => report?.[subLink.key])
          : link.subList,
      }))
    );
  }, [report]);

  const value = useMemo(
    () => ({
      highlightedSectionBlock,
      setHighlightedSectionBlock,
      navLinks,
      subNavLinkSet,
    }),
    [
      highlightedSectionBlock,
      setHighlightedSectionBlock,
      navLinks,
      subNavLinkSet,
    ]
  );

  return (
    <SidebarContext.Provider value={value}>{children}</SidebarContext.Provider>
  );
}

export function useSidebarContext() {
  const context = useContext(SidebarContext);

  if (!context) {
    throw new Error(
      "useSidebarContext must be used within a SidebarContextProvider"
    );
  }

  return context;
}

/*
  NavigationScrollListener is the scroll component that contains the logic to highlight the 
  current section in the sidebar based on the scroll position of the its content.
*/
export function NavigationScrollListener(props) {
  const containerRef = useRef(null);

  const { highlightedSectionBlock, subNavLinkSet, setHighlightedSectionBlock } =
    useSidebarContext();

  const handleScroll = () => {
    const query = Array.from(subNavLinkSet)
      .map((key) => `#${key}`)
      .join(", ");

    const elements = containerRef.current.querySelectorAll(query);

    const current = Array.from(elements).find((el) => {
      const rect = el.getBoundingClientRect();
      return rect.top > 0 && rect.top < window.innerHeight / 2;
    });

    if (current) {
      const id = current.id;
      if (highlightedSectionBlock !== id) {
        setHighlightedSectionBlock(id);
      }
    }

    props.onScroll?.();
  };

  return <div {...props} ref={containerRef} onScroll={handleScroll} />;
}
