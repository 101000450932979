import { useState } from "react";
import AccordionContent from "./AccordionContent";
import AccordionTitle from "./AccordionTitle";

export default function Accordion({
  children,
  className = "accordion",
  icon,
  list,
  title,
  type = "basic",
  isOpen,
  url,
  openAccordion
}) {

  const toggleAccordion = () => {
    openAccordion(url);
  };

  return (
    <div
      className={"accordion " + className + (isOpen ? ` accordion--open` : "")}
    >
      <AccordionTitle icon={icon} isOpen={isOpen} onClick={toggleAccordion}>
        {title}
      </AccordionTitle>
      <div className="accordion-content-wrapper">
        <AccordionContent list={list} type={type} content={children} />
      </div>
    </div>
  );
}
